import axios from 'axios'


const API = 'cuentas'

const datosCuentas = {
    listarCuentasClientes(){
        return axios(`${API}/clientes`)
    },
    listarCuentasLeecheros(){
        return axios(`${API}/leecheros`)
    },
    listarActualizacionesClientes(){
        return axios(`${API}/1/actualizaciones`)//Consulta los datos de las actualizaciones del cliente
    },
    listarActualizacionesLeecheros(){
        return axios(`${API}/2/actualizaciones`)//Consulta los datos de las actualizaciones del leechero
    },
    cancelarActualizacion(id){
        return axios(`${API}/${id}/cancelar-actualizacion`)//Cancelar actualizacion
    },
    listarCedis(){
        return axios(`${API}/listar-cedis`)
    },
    crearSolicitudes(params){
        return axios.post(`${API}/crear-solicitudes`,params)
    },
    setDias(form){
        return axios.post(`${API}/set-dias`,form);
    }

}

export default datosCuentas
