<template>
    <section class="pr-3">
        <div class="row mx-0">
            <div :class="$can('boton_datos_actualizaciones_crear_solicitudes')? '':'disabled-div'" class="col bg-white br-8 py-3 px-4 mr-3">
                <div class="row mx-0 align-items-center">
                    <el-radio-group v-model="radio" class="col-auto" @change="onChange()">
                        <el-radio class="radio-general" :label="1">
                            Direccion
                        </el-radio>
                        <el-radio class="radio-general" :label="2">
                            Contraseña
                        </el-radio>
                        <el-radio class="radio-general" :label="3">
                            Datos
                        </el-radio>
                    </el-radio-group>
                    <div v-if="radio==3">
                        <el-checkbox v-model="nacimiento" class="check-dark">
                            Fecha de nacimiento
                        </el-checkbox>
                        <el-checkbox v-model="genero" class="check-dark">
                            Género
                        </el-checkbox>
                        <el-checkbox v-model="facturacion" class="check-dark">
                            Facturación
                        </el-checkbox>
                    </div>
                </div>
                <div class="row mx-0 align-items-center mt-3">
                    <div class="col-auto px-0 text-general">
                        Duración del lanzamiento
                    </div>
                    <div class="col-3">
                        <el-date-picker
                        v-model="fecha"
                        type="daterange"
                        class="w-100"
                        size="small"
                        value-format="yyyy-MM-dd"
                        range-separator=""
                        start-placeholder="Fecha inicio"
                        end-placeholder="Fecha fin"
                        :clearable="false"
                        />
                    </div>
                    <div class="btn-general text-white cr-pointer px-3 f-14" @click="solicitarActualizar()">
                        Solicitar actualización
                    </div>
                </div>
            </div>
            <div class="bg-white py-3 px-2 br-8" style="width:258px;">
                <div class="row mx-0 text-general justify-center f-17">
                    <p class="col-12 d-middle-center">
                        Días de no molestar 
                        <el-tooltip placement="bottom" effect="light">
                            <div slot="content" class="text-general2 ml-3" style="max-width:180px">
                                Son los dias que no aparecen el mensaje de una solicitud de actualización al usuario desde la ultima vez que se abró
                            </div>
                            <i class="icon-help-circle-outline text-general f-20" />
                        </el-tooltip>
                    </p>
                    <div class="col-8 mt-2">
                        <el-input-number v-model="dias" :controls="false" placeholder="Días" class="w-100" size="small" :disabled="$can('boton_datos_cuentas_set_dias')? false : true " @change="setDias()" />
                    </div>
                </div>
            </div>
        </div>
        <div class="row mx-0 mt-3">
            <div class="col-12 px-0">
                <tabla-general :data="datos" class-header="text-general f-16 f-500" alto="calc(100vh - 405px)">
                    <template slot="cabecera-izquierda">
                        <div class="col px-0 d-middle">
                            <div class="col-auto text-general f-17 f-500">
                                Clientes con datos faltantes
                            </div>
                            
                            <div class="col-auto text-general f-22 f-600 ml-auto mr-3 px-0">
                                {{ agregarSeparadoresNumero(faltantes) }}
                            </div>
                        </div>
                    </template>
                    <template slot="adicionales-izquierda">
                        <el-table-column
                        class-name="text-center"
                        width="80"
                        >
                            <template slot-scope="scope">
                                <i :class="`${estadosLeecheros(scope.row.estado)} f-20`" />
                            </template>
                        </el-table-column>
                        <el-table-column
                        label="Actualización"
                        class-name="text-center"
                        width="200"
                        >
                            <template slot-scope="scope">
                                <p class="f-12 cr-pointer" style="color:#606266;" @click="actualizarDatos(scope.row)">
                                    {{ formatearFecha(scope.row.fecha_inicio) }} - {{ formatearFecha(scope.row.fecha_fin) }}
                                </p>
                            </template>
                        </el-table-column>
                        <el-table-column
                        prop="cedis"
                        label="Cedis"
                        min-width="150"
                        sortable
                        >
                            <template slot-scope="{row}">
                                <el-tooltip placement="bottom" effect="light">
                                    <template slot="content">
                                        <p v-for="(c, d) in row.cedis" :key="d" class="text-general2 px-2">
                                            {{ c.cedis }}
                                        </p>
                                    </template>
                                    <div class="row mx-0 align-left">
                                        {{ row.cedis.length ? row.cedis[0].cedis +cedis(row.cedis): "-" }}
                                    </div>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                        <el-table-column
                        prop="nombre"
                        label="Nombre"
                        min-width="150"
                        >
                            <template slot-scope="scope">
                                <div class="row mx-0 justify-center">
                                    {{ scope.row.admin }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                        label="Omitieron"
                        min-width="150"
                        class-name="text-center"
                        >
                            <template slot-scope="scope">
                                <div class="row mx-0 justify-center">
                                    {{ scope.row.rechazadas }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                        prop="actualizaron"
                        label="Actualizaron"
                        min-width="150"
                        >
                            <template slot-scope="scope">
                                <div class="row mx-0 justify-center">
                                    {{ scope.row.aceptadas }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                        min-width="150"
                        prop="no_abrieron"
                        label="No abrieron"
                        >
                            <template slot-scope="scope">
                                <div class="row mx-0 justify-center">
                                    {{ (faltantes)-(scope.row.aceptadas+scope.row.rechazadas) }}
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                        label="Fecha nacimiento"
                        min-width="150"
                        >
                            <template slot-scope="scope">
                                <div v-if="scope.row.nacimiento" class="row mx-0 justify-center">
                                    <i class="icon-ok-circled-outline text-green f-20" />
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                        class-name="text-center"
                        label="Género"
                        min-width="150"
                        >
                            <template slot-scope="scope">
                                <div v-if="scope.row.genero" class="row mx-0 justify-center">
                                    <i class="icon-ok-circled-outline text-green f-20" />
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                        label="Dirección"
                        min-width="150"
                        >
                            <template slot-scope="scope">
                                <div v-if="scope.row.direccion" class="row mx-0 justify-center">
                                    <i class="icon-ok-circled-outline text-green f-20" />
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                        label="Facturación"
                        min-width="150"
                        >
                            <template slot-scope="scope">
                                <div v-if="scope.row.facturacion" class="row mx-0 justify-center">
                                    <i class="icon-ok-circled-outline text-green f-20" />
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column
                        label="Contraseña"
                        min-width="150"
                        >
                            <template slot-scope="scope">
                                <div v-if="scope.row.password" class="row mx-0 justify-center">
                                    <i class="icon-ok-circled-outline text-green f-20" />
                                </div>
                            </template>
                        </el-table-column>
                    </template>
                </tabla-general>
            </div>
        </div>
        <!-- Partials -->
        <modal-actualizar-datos ref="modalActualizarDatos" @update="listarLeecheros" />
        <modal-solicitar-actualizar ref="modalSolicitarActualizar" @update="listarLeecheros" />
    </section>
</template>

<script>
import {mapGetters} from 'vuex'
import datosCuentas from '~/services/DatosCuentas/datosCuentas'
export default {
    components: {
        modalActualizarDatos: () => import('../partials/modalActualizarDatos'),
        modalSolicitarActualizar: () => import('../partials/modalSolicitarActualiza')
    },
    data(){
        return {
            fecha: [],
            dias: "",
            faltantes: 0,
            nacimiento: false,
            genero: false,
            direccion: '',
            facturacion: false,
            password: '',
            radio: 1,
            input: '',
            datos: []
        }
    },
    computed:{
        ...mapGetters({
            id_cedis:'cedis/id_cedis',
        }),
    },
    watch: {
        id_cedis(val){
            this.listarLeecheros()
        },
    },
    mounted(){
        this.listarLeecheros()
    },
    methods: {
        estadosLeecheros(state){
            switch (state){
            case 1:
                return 'icon-ok-circled-outline text-green';
            case 2:
                return 'icon-cancel-circled-outline text-general-red';
            }
        },
        actualizarDatos(row){
            row.faltantes = this.faltantes
            this.$refs.modalActualizarDatos.toggle(row);
        },
        solicitarActualizar(){
            let form = {
                nacimiento: this.nacimiento,
                genero: this.genero,
                direccion: this.radio == 1 ? 1 : 0,
                facturacion: this.facturacion,
                password: this.radio == 2 ? 1 : 0,
                fecha: this.fecha
            }
            if(this.radio == 3 && (!this.nacimiento && !this.genero && !this.facturacion)) return this.notificacion("Error","Debe seleccionar al menos una opción de actualización","warning")
            if(!this.fecha.length) return this.notificacion("Error","Debe seleccionar al menos un rango de fecha","warning")
            this.$refs.modalSolicitarActualizar.toggle(form);
            
        },
        async listarLeecheros(){
            try{
                const {data} = await datosCuentas.listarActualizacionesLeecheros()
                this.datos = data.data
                this.dias = data.dias.texto
                this.faltantes = data.faltantes
            }catch(error){
                return this.error_catch(error)
            }

        },

        cedis(cedis){
            if(cedis.length>1){
                return " +"+(cedis.length-1);
            }
            return "";
        },
        async setDias(){
            const origen = this.$route.name
            const cliente = 'admin.datos-cuentas.lanzamientos.clientes'
            const leechero = 'admin.datos-cuentas.lanzamientos.leecheros'
            let destino
            switch (origen){
            case cliente:
                destino = 61    
                break;
            case leechero:
                destino = 62   
                break;
            default:
                break;
            }
            let form = {
                destino: destino,
                dias: this.dias
            };
            try {
                const {data} = await datosCuentas.setDias(form);
                return this.notificacion("Éxito","Días actualizados correctamente","success");
            } catch (error){
                this.error_catch(error)
            }
            
        },
        onChange(){
            this.nacimiento = false
            this.genero = false
            this.facturacion = false
        }
    }
}
</script>